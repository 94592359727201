.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #17a2b8;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.nav-sidebar .menu-is-opening>.nav-link i.right, .nav-sidebar .menu-open>.nav-link i.right {
    transform: rotate(90deg);
}

.card {
    transition: transform 0.3s ease;
    margin-top: 2%;
    border-radius: 20px;
    box-shadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
}



.tableFixHead {
    overflow-y: auto;
    height: 300px;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
}

/* Just common table stuff. Really. */

table {
    width: 100%;
}

th {
    background: white;
}

ul {
    list-style: none;
    padding-left: 20px;
    padding-right: 20px;
}

.spantag {
    padding-left: 10px;
}

.marginn {
    padding-top: 3px;
}

#pushbutton {
    display: none;
}

@media(max-width: 767px) {
    #pushbutton {
        display: block;
    }
}

.background {
    background: radial-gradient(black, transparent);
}

#file-chosen {
    padding: 5px 20px;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

#file-chosen:hover {
    transform: scale(1.01);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}

.markup {
    width: 40%;
    text-align: center
}

.markup:hover {
    transform: scale(1.01);
    box-shadow: 0 10px 20px rgb(0 0 0 / 12%), 0 4px 8px rgb(0 0 0 / 6%);
}

.partybackground {
    background: #0B486B;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #F56217, #0B486B);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #F56217, #0B486B);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
}