
.pagination{
    width: 100%;
}
.removestyle{
    border: none;
    background: white;
    font-weight: bold;
    color: black;
}

.table tr th div button{
    display: inline-flex;
    
}
.showingtotal{
    margin-top: 5px;
    margin-bottom: 10px;
}
.dropdown-set{
    top:10px !important;
}
.removelist ul li{
    list-style: none;
}
.removelist ul{
    height: 200px;
    overflow: auto;
}
.hide{
    display: none;
}

.bg-custom-blue {
    background-color: #0F67B1;
    color: white;
}
  